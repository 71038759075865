var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":true,"title":"编辑人员","maskClosable":false,"width":600,"confirm-loading":_vm.confirmLoading,"closable":false},on:{"ok":_vm.submit,"cancel":_vm.cancel}},[_c('a-form-model',{ref:"ruleForm",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"model":_vm.form,"rules":_vm.rules}},[_c('a-form-model-item',{attrs:{"label":"手机号","required":""}},[_vm._v(" "+_vm._s(_vm.form.phone)+" ")]),_c('a-form-model-item',{staticStyle:{"margin-top":"20px"},attrs:{"label":"角色：","prop":"roleIds"}},[_c('a-select',{attrs:{"placeholder":"请选择角色类型","mode":"multiple","getPopupContainer":function (triggerNode) { return triggerNode.parentNode; }},model:{value:(_vm.form.roleIds),callback:function ($$v) {_vm.$set(_vm.form, "roleIds", $$v)},expression:"form.roleIds"}},_vm._l((_vm.roleTypeList),function(item){return _c('a-select-option',{key:item.roleTypeId,attrs:{"value":item.roleTypeId}},[_vm._v(_vm._s(item.roleTypeName))])}),1)],1),_c('a-form-model-item',{attrs:{"label":"部门：","prop":"organizationId"}},[_c('a-tree-select',{attrs:{"dropdown-style":{ maxHeight: '300px', overflow: 'auto' },"tree-data":_vm.departmentList,"placeholder":" 请选择部门","tree-default-expand-all":"","replaceFields":{
            children: 'childList',
            title: 'organizationName',
            key: 'organizationId',
            value: 'organizationId',
          },"getPopupContainer":function (triggerNode) {
  return triggerNode.parentNode || _vm.document.body;
}},model:{value:(_vm.form.organizationId),callback:function ($$v) {_vm.$set(_vm.form, "organizationId", $$v)},expression:"form.organizationId"}})],1),_c('a-form-model-item',{attrs:{"label":"飞书open_id：","prop":"feishuid"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{staticClass:"inputClass",attrs:{"placeholder":""},model:{value:(_vm.form.openId),callback:function ($$v) {_vm.$set(_vm.form, "openId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.openId"}}),_c('a-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary"},on:{"click":_vm.getFeishuOpenid}},[_c('a-icon',{attrs:{"type":"search"}}),_vm._v(" 通过手机号查询")],1)],1)]),_c('a-form-model-item',{staticStyle:{"margin-top":"20px"},attrs:{"label":"服务项目：","prop":"projectIdList"}},[_c('a-select',{attrs:{"placeholder":"请选择服务项目","mode":"multiple","getPopupContainer":function (triggerNode) { return triggerNode.parentNode; }},model:{value:(_vm.form.projectIdList),callback:function ($$v) {_vm.$set(_vm.form, "projectIdList", $$v)},expression:"form.projectIdList"}},_vm._l((_vm.projectList),function(item){return _c('a-select-option',{key:item.projectId,attrs:{"value":item.projectId}},[_vm._v(_vm._s(item.projectName))])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }